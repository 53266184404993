import { LogistKpiOutEntity } from "./../api/kuber-api/codegen/models/LogistKpiOutEntity";
import { admin, auth, tickets } from "@/api/kuber-api";
import {
  AdminMenuItem,
  CalculateTariffRequest,
  CreateTn2022Request,
  Get1CCompanyListRequest,
  GetAdminAllManualCheckListRequest,
  GetAdminBankPaymentListRequest,
  GetAdminCreditListRequest,
  GetAdminDigitizationListRequest,
  GetAdminInvoiceListRequest,
  GetAdminOperationListRequest,
  GetAdminPostpaymentListRequest,
  GetAdminWithdrawListRequest,
  GetAssistantKpiListFilter,
  GetBriefDocPackageListRequest,
  GetBriefDowntimeOrderListRequest,
  GetBriefOrderListGeneralRequest,
  GetBriefOrderListRequest,
  GetCheckDetailsResponse,
  GetCustomerDocsPackageListRequest,
  GetLogistKpiListRequest,
  GetMoverDocsListRequest,
  GetMoverDocsPackageListRequest,
  GetMoverTransportDocsListRequest,
  GetPromosRequest,
  GetTicketChatRequest,
  GetUserInfoResponse,
  GetWidgetCompanyFilterRequest,
  GetWidgetFilterRequest,
  KpiEntity,
  RecalculateTariffOrderEntityResponse,
  SearchUsersRequest,
  SearchUsersResponse,
  TariffOrderEntityResponse,
  WidgetEntity,
} from "@/domain/admin";
import {
  AuthTokensResponse,
  EntityWithProfileRequest,
  FIRST_MILE_DELIVERY_TYPE,
  GetPaginatedAdminTicketChatsListResponse,
  GetTicketChatDirectoryResponse,
  LAST_MILE_DELIVERY_TYPE,
  ResultResponse,
  SignInRequest,
  UniqueEntity,
} from "@/domain";
import { transformError } from "@/shared";
import { EditUserFormState, ManualDigitizationFormState, UncheckedVerificationsRequest } from "@/admin/data/form/types";
import {
  AddBankBillAdminController_BankPersonalBillAGRequest,
  AddBankStatementBillAdminController_BankStatementBillAGAdminRequest,
  AddCreditCustomerAdminController_CreditCustomerRequest,
  AddDocumentToOrderController_AgAddDocumentToOrderRequest,
  AddDowntimeToOrderController_AgAddDowntimeRequest,
  AddFileToMoverDocumentPackageAdminController_AgAddFileToMoverDocumentPackageRequest,
  AdminLogistEntity,
  AdminRecalculationTariffController_RecalculationTariffAdminRequest,
  AdminTariffEmptyMileController_PublicTariffCalculationEmptyContainerRequest,
  AdminTariffsDowntimeController_AdminTariffDowntimeRequest,
  AdminTariffsDowntimeController_AdminTariffDowntimeResponse,
  AdminTariffsFirstMileController_PublicTariffCalculationFirstMileRequest,
  AdminTariffsLastMileController_PublicTariffCalculationLastMileRequest,
  AdvancedCustomerCreditRepaymentAdminController_CustomerCreditRepaymentAdminAGRequest,
  type AgGetMoverDocumentPackageBriefInfoListAdminItemEntity,
  type AgGetOrderBriefInfoListAdminItemEntity,
  AgOrderIdCodeListEntity,
  AgOrderShortInfoListAdminEntity,
  type AgOrderStatusEntity,
  AgUserDowntimeEntity,
  CancelOrderAdminController_AgOrderCancelRequest,
  ChangeContainerDeliveredAtController_AgChangeContainerDeliveredAtRequest,
  ChangeResponsibleLogistForCustomerController_ChangeResponsibleLogistForCustomerRequest,
  ChangeResponsibleLogistForOrderController_AgChangeResponsibleLogistForOrderRequest,
  ChangeTransportOwnerController_ChangeTransportOwnerRequest,
  ChangeUserRolesController_ChangeUserRolesRequest,
  CloseTicketController_TicketCloseRequest,
  CompletedWorkActEntity,
  CreateNewMoverDocumentPackageAdminController_AgCreateNewMoverDocumentPackageRequest,
  CreatePromoCodesAdminController_NewPromoCodesRequest,
  DeclineDocumentDigitizationAdminController_AgDeclineDigitizationAdminRequest,
  DeleteTicketMessageAdminController_TicketMessageDeleteAdminRequest,
  DigitizeDocumentAdminController_AgDigitizeDocumentManuallyAdminRequest,
  FinishOrderCustomerController_AgOrderIdCodeRequest,
  FinishOrderMoverController_AgOrderIdCodeRequest,
  GenerateClosingDocumentsForOrders_AgGenerateClosingDocumentsForOrdersRequest,
  GenerateCustomerDocumentPackageController_AgGenerateCustomerDocumentPackageRequest,
  GetAllDigitizationsInStatusResponseEntity,
  GetAllManualChecksController_WorkflowAllManualChecksResponse,
  GetAllOrdersWithoutContainerNumberResponseEntity,
  GetBillingApplicationWithdrawAdminController_BillingApplicationWithdrawAdminResponse,
  GetBillingBankBillAdminController_BankBillOperationAdminResponse,
  GetBillingCreditIssuedCustomerAdminController_CreditIssuedCustomerResponse,
  GetBillingCreditOrderCustomerAdminController_CreditOrderCustomerAdminResponse,
  GetBillingHistoryPersonalOperationAdminController_HistoryPersonalOperationAdminResponse,
  GetBillingInvoiceAdminController_BillingInvoiceAdminResponse,
  GetClosedTicketInfoByAdminController_ClosedTicketInfoAdminResponse,
  GetCompanyWith1CInfoListAdminController_AgCompanyWith1CInfoListAdminResponse,
  GetCustomerDocumentPackageEntityListAdminController_AgCustomerDocumentPackageListAdminResponse,
  GetDigitizationsListAdminController_AgGetDigitizationsListAdminResponse,
  GetDowntimeListAdminController_AgGetDowntimeListAdminRequest,
  GetDowntimeListAdminController_AgDowntimeListAdminResponse,
  GetDriverListController_DriverListRequest,
  GetDriverListController_DriverListResponse,
  GetLogistListController_LogistListResponse,
  GetMoverDocumentPackageEntityListAdminController_AgMoverDocumentPackageEntityListAdminResponse,
  GetMoverDocumentPackageFileEntityListAdminController_AgMoverDocumentPackageFileEntityListAdminResponse,
  GetMoverTransportDocumentsListAdminController_AgMoverTransportDocumentsListAdminResponse,
  GetOrderListAdminController_AgGetOrderListAdminRequest,
  GetOrderListAdminController_AgOrderListAdminResponse,
  GetPromoCodesCustomerAdminController_TariffCalculationPromoCodesCustomerAdminResponse,
  GetSemiTrailerListController_SemiTrailerListResponse,
  GetSemiTrailerListController_TruckAndSemiTrailerListRequest,
  GetTicketIdAdminController_TicketIdNullableResponse,
  GetTicketIdAdminController_TicketTitleAndUserCodeRequest,
  GetTruckListController_TruckAndSemiTrailerListRequest,
  GetTruckListController_TruckListResponse,
  GetUserIdByParamsController_UserIdResponse,
  GetUserInfoByAdminController_UserIdAdminRequest,
  GetUserRolesController_UserRolesResponse,
  GetUsersListController_AgUsersListRequest,
  type ListCompanyWidgetOrdersOut,
  MoveTicketMessageController_TicketMessageMoveRequest,
  OpenTicketAdminController_TicketAndTicketMessageAdminRequest,
  PromoCodeAdminEntity,
  SendManualCheckController_ManualCheckRequest,
  SetCustomerDocumentPackageOriginalDocumentsSentController_AgSetCustomerDocumentPackageOriginalDocumentsSentRequest,
  SetMoverDocumentPackageFileOriginalReceivedController_AgMoverDocumentPackageFileOriginalReceivedRequest,
  SetMoverTransportDocumentOriginalReceivedController_AgMoverTransportDocumentOriginalReceivedRequest,
  SetOrderStatusController_AgSetOrderStatusWithParamsRequest,
  UpdateContractor1CInfoAdminController_AgContractor1CInfoAdminRequest,
  UpdateMoverDocumentPackageFileAdminController_AgUpdateMoverDocumentPackageFileStatusRequest,
  UpdateStatusPromCodeAdminController_StatusPromoCodeRequest,
  UpdateTariffAdminController_AgRecalculationOrderTariffAdminRequest,
  UploadMoverDocumentPackageFileAdminController_AgUploadMoverDocumentPackageFileAdminRequest,
  type UserShortInfoEntity,
  UserSpoofingController_SecurityTokenResponse,
  UserUuidEntity,
  WithdrawMoverBalanceAdminController_ApplicationWithdrawAdminRequest,
  WithdrawUserBalanceAdminController_ResultBoolResponse,
  WithdrawUserBalanceAdminController_WithdrawBalanceInvoiceRequest,
  type WorkflowCheckEntity,
  OrdersForCheckCustomerDocumentPackageController_OrdersForCheckDocumentPackageResponse,
  OrdersForCheckCustomerDocumentPackageController_OrdersForCheckDocumentPackageRequest,
  OrdersForCheckDocumentPackageOutEntity,
  GetLogistKpiController_LogistKpiResponse,
  UploadFileAndCreateDigitizationAdminController_AgUploadFileAndCreateDigitizationAdminRequest,
  UploadFileAndCreateDigitizationAdminController_ResultBoolResponse,
  OneLogistKpiOutEntity,
  OrdersForCheckMoverDocumentPackageController_OrdersForCheckDocumentPackageResponse,
  CancelCustomerDocumentPackageController_ResultBoolResponse,
  CancelCustomerDocumentPackageController_AgCancelCustomerDocumentPackageRequest,
  ChangeOrderContainerNumberController_AgChangeOrderContainerNumberRequest,
  ChangeOrderContainerNumberController_ResultBoolResponse,
  ChangeOrderPickupTerminalController_AgChangeOrderPickupTerminalRequest,
  ChangeOrderPickupTerminalController_ResultBoolResponse,
  ChangeOrderDeliveryTerminalController_AgChangeOrderDeliveryTerminalRequest,
  ChangeOrderDeliveryTerminalController_ResultBoolResponse,
  ChangeOrderRejectMoverController_AgChangeOrderRejectMoverRequest,
  ChangeOrderRejectMoverController_ResultBoolResponse,
  CancelMoverDocumentPackageController_ResultBoolResponse,
  CancelMoverDocumentPackageController_AgCancelMoverDocumentPackageRequest,
  AddFileToMoverDocumentPackageAdminController_ResultBoolResponse,
  CreateMoverDocumentPackageAdminController_ResultBoolResponse,
  CreateMoverDocumentPackageAdminController_AgCreateNewMoverDocumentPackageV2Request,
  AddFileToMoverDocumentPackageV2AdminController_AgAddFileToMoverDocumentPackageV2Request,
  AddFileToMoverDocumentPackageV2AdminController_ResultBoolResponse,
  FinancialStatisticsController_FinancialStatisticsAdminResponse,
  GetRegistryOrdersForCustomerEntityListController_AgRegistryOrdersCustomerListResponse,
  CreateRegistryOrdersForCustomerController_ResultBoolResponse,
  CreateRegistryOrdersForCustomerController_AgCreateRegistryOrdersForCustomerRequest,
  ChangeRegistryOrdersForCustomerController_AgChangeRegistryOrdersForCustomerRequest,
  ChangeRegistryOrdersForCustomerController_ResultBoolResponse,
  GenerateCustomerDocumentPackageFromRegistryController_AgGenerateCustomerDocumentPackageFromRegistryRequest,
  GenerateCustomerDocumentPackageFromRegistryController_ResultBoolResponse,
  CancelRegistryOrdersCustomerController_AgCancelRegistryOrdersForCustomerRequest,
  CancelRegistryOrdersCustomerController_ResultBoolResponse,
  FinancialStatisticsAccountTablesController_FinancialAccountTablesStatisticsAdminResponse,
  FinancialAccountTablesStatisticsAdminOutEntity,
  AccountTablesTypeEntity,
  RegistryOrdersCustomerEntity,
  GetRegistryOrdersListForMoverController_AgRegistryOrdersMoverListResponse,
  CreateRegistryOrdersForMoverController_AgCreateRegistryOrdersForMoverRequest,
  CreateRegistryOrdersForMoverController_ResultBoolResponse,
  CountOrdersStatisticsAdminController_CountOrdersStatisticsAdminResponse,
  GetLogistAssistantKpiController_LogistAssistantKpiResponse,
  RestoreFromArchiveAtiCargoController_ResultBoolResponse,
  RestoreFromArchiveAtiCargoController_AgRestoreFromArchiveAtiCargoRequest,
  ChangeActiveDowntimeForUserController_AgChangeActiveDowntimeForUserRequest,
  ChangeActiveDowntimeForUserController_ResultBoolResponse,
} from "@/api/kuber-api/codegen";
import { roles } from "@/data/constants";
import {
  toRecalculateTariffOrderEntityResponse,
  toTariffOrderEntityResponse,
} from "@/api/kuber-api/admin/admin-mappers";
import { WidgetDictionary } from "@/admin/dashboard/Widgets";
import { AdminLogistInfoEntity } from "@/admin/hooks/store/useUserInfoStore";

export function createTn2022Manually(request: CreateTn2022Request) {
  return admin.api
    .createTn2022Manually(admin.mappers.fromCreateTn2022Request(request))
    .then(admin.mappers.toCreateTn2022Response);
}

export async function checkVerificationManually(request: SendManualCheckController_ManualCheckRequest) {
  return admin.api
    .checkVerificationManually(request)
    .then((response) => response)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function generateClosingDocs(
  request: GenerateClosingDocumentsForOrders_AgGenerateClosingDocumentsForOrdersRequest,
) {
  return admin.api
    .generateClosingDocs(request)
    .then((response) => response)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function setOrderStatus(request: SetOrderStatusController_AgSetOrderStatusWithParamsRequest) {
  return admin.api
    .setOrderStatus(request)
    .then((response) => response)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function getAllManualDigitizations(): Promise<GetAllDigitizationsInStatusResponseEntity[]> {
  return admin.api
    .getAllManualDigitizations()
    .then((response) => response.entities ?? [])
    .catch((error) => {
      throw transformError(error);
    });
}

export async function getAllUserVerifications(request: UncheckedVerificationsRequest) {
  try {
    return admin.api.getAllUserVerifications(request).then((data) => data.check_uuid_list);
  } catch (error) {
    throw transformError(error);
  }
}

export async function verifyManualDigitization(request: ManualDigitizationFormState) {
  return admin.api
    .verifyManualDigitization(request)
    .then((response) => response.entity)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function finishOrder(
  request: EntityWithProfileRequest<
    FinishOrderCustomerController_AgOrderIdCodeRequest | FinishOrderMoverController_AgOrderIdCodeRequest
  >,
) {
  try {
    return request.profileType === roles.CUSTOMER
      ? await admin.api.finishCustomerOrder(request.payload)
      : await admin.api.finishMoverOrder(request.payload);
  } catch (error) {
    throw transformError(error);
  }
}

export function signIn(request: SignInRequest): Promise<AuthTokensResponse> {
  return auth.api
    .signIn(auth.mappers.fromSignInRequest(request))
    .then((response) => auth.mappers.toAuthTokensResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export async function getOrdersForClosingDocs(request: UniqueEntity): Promise<string[]> {
  try {
    return admin.api.getOrdersForClosingDocs(request).then((response) => response.orders ?? []);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOrdersReadyForFinish(
  request: EntityWithProfileRequest<UniqueEntity>,
): Promise<AgOrderIdCodeListEntity[]> {
  try {
    const response =
      request.profileType === roles.CUSTOMER
        ? await admin.api.getCustomerOrdersReadyForFinish(request.payload)
        : await admin.api.getMoverOrdersReadyForFinish(request.payload);
    return response.entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function addCredit(
  request: AddCreditCustomerAdminController_CreditCustomerRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.addCredit(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function syncTransportWithAti(request: { userId: string; value: string }) {
  try {
    return await admin.api.syncTransportWithAti(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserIdByParams(request: {
  identifier: string;
  value: string;
}): Promise<GetUserIdByParamsController_UserIdResponse> {
  try {
    return await admin.api.getUserIdByParams(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function editUser(request: EditUserFormState): Promise<ResultResponse> {
  try {
    const response = await admin.api.editUser(admin.mappers.toEditUserRequest(request));
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserRoles(request: { userId: string }): Promise<GetUserRolesController_UserRolesResponse> {
  try {
    return await admin.api.getUserRoles(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUsers(request: GetUsersListController_AgUsersListRequest): Promise<SearchUsersResponse> {
  try {
    const response = await admin.api.getUsers(request);
    return admin.mappers.toSearchUsersResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function calculateTariffFirstMile(
  request: AdminTariffsFirstMileController_PublicTariffCalculationFirstMileRequest,
): Promise<TariffOrderEntityResponse | undefined> {
  try {
    return await admin.api
      .calculateTariffFirstMile(request)
      .then((response) => toTariffOrderEntityResponse(response.tariff_order_entity));
  } catch (error) {
    throw transformError(error);
  }
}

export async function calculateTariffLastMile(
  request: AdminTariffsLastMileController_PublicTariffCalculationLastMileRequest,
): Promise<TariffOrderEntityResponse | undefined> {
  try {
    return await admin.api
      .calculateTariffLastMile(request)
      .then((response) => toTariffOrderEntityResponse(response.tariff_order_entity));
  } catch (error) {
    throw transformError(error);
  }
}

export async function calculateTariffEmptyMile(
  request: AdminTariffEmptyMileController_PublicTariffCalculationEmptyContainerRequest,
): Promise<TariffOrderEntityResponse | undefined> {
  try {
    return await admin.api
      .calculateTariffEmptyMile(request)
      .then((response) => toTariffOrderEntityResponse(response.tariff_order_entity));
  } catch (error) {
    throw transformError(error);
  }
}

export async function calculateTariff(request: CalculateTariffRequest): Promise<TariffOrderEntityResponse | undefined> {
  switch (request.deliveryType) {
    case FIRST_MILE_DELIVERY_TYPE:
      return calculateTariffFirstMile(admin.mappers.toFirstMileTariffRequest(request.payload));

    case LAST_MILE_DELIVERY_TYPE:
      return calculateTariffLastMile(admin.mappers.toLastMileTariffRequest(request.payload));

    default:
      return calculateTariffEmptyMile(admin.mappers.toEmptyMileTariffRequest(request.payload));
  }
}

export async function generateCustomerDocumentPackage(
  request: GenerateCustomerDocumentPackageController_AgGenerateCustomerDocumentPackageRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.generateCustomerDocumentPackage(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOrdersWithoutContainers(): Promise<GetAllOrdersWithoutContainerNumberResponseEntity[]> {
  try {
    const response = await admin.api.getOrdersWithoutContainers();
    return response.entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getPromos(
  request: GetPromosRequest,
): Promise<GetPromoCodesCustomerAdminController_TariffCalculationPromoCodesCustomerAdminResponse> {
  try {
    return await admin.api.getPromos(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getPromoInfo(): Promise<PromoCodeAdminEntity | undefined> {
  try {
    const response = await admin.api.getPromoInfo();
    return response.promo_code_info_output_entity?.promo_code_entity ?? undefined;
  } catch (error) {
    throw transformError(error);
  }
}

export async function createPromo(request: {
  payload: CreatePromoCodesAdminController_NewPromoCodesRequest;
  isSpecial: boolean;
}): Promise<ResultResponse> {
  const isSpecial = request.isSpecial;
  try {
    const response = isSpecial
      ? await admin.api.createSpecialPromo(request.payload)
      : await admin.api.createPromo(request.payload);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changePromo(
  request: UpdateStatusPromCodeAdminController_StatusPromoCodeRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changePromo(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function addRoles(request: ChangeUserRolesController_ChangeUserRolesRequest): Promise<ResultResponse> {
  try {
    const response = await admin.api.addRoles(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserInfo(
  request: GetUserInfoByAdminController_UserIdAdminRequest,
): Promise<GetUserInfoResponse> {
  try {
    const response = await admin.api.getUserInfo(request);
    return admin.mappers.toUserInfoResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

// SUPPORT

export async function getAdminTicketChatList(
  request: GetTicketChatRequest,
): Promise<GetPaginatedAdminTicketChatsListResponse> {
  try {
    const response = await admin.api.getAdminTicketChats({ pagination: request.pagination, filter: request.filter });

    return admin.mappers.toGetTicketChatListResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getAdminTicketChatDirectory(request: {
  user_id: string;
}): Promise<GetTicketChatDirectoryResponse> {
  try {
    const response = await admin.api.getAdminTicketChatDirectory(request);

    return tickets.mappers.toGetTicketChatDirectoryResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function closeTicketChat(request: CloseTicketController_TicketCloseRequest): Promise<ResultResponse> {
  try {
    const response = await admin.api.closeTicketChat(request);

    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function openTicketChat(
  request: OpenTicketAdminController_TicketAndTicketMessageAdminRequest,
): Promise<string> {
  try {
    const response = await admin.api.openAdminTicketChat(request);

    return response.ticket_id;
  } catch (error) {
    throw transformError(error);
  }
}

export async function moveTicketChatMessages(
  request: MoveTicketMessageController_TicketMessageMoveRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.moveTicketChatMessages(request);

    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function deleteTicketChatMessages(
  request: DeleteTicketMessageAdminController_TicketMessageDeleteAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.deleteTicketChatMessages(request);

    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getClosedTicketChatInfo(request: {
  ticketId: string;
}): Promise<GetClosedTicketInfoByAdminController_ClosedTicketInfoAdminResponse> {
  try {
    return await admin.api.getClosedTicketChatInfo(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTicketChatId(
  request: GetTicketIdAdminController_TicketTitleAndUserCodeRequest,
): Promise<GetTicketIdAdminController_TicketIdNullableResponse> {
  try {
    return await admin.api.getTicketChatId(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOrderList(
  request: GetOrderListAdminController_AgGetOrderListAdminRequest,
): Promise<GetOrderListAdminController_AgOrderListAdminResponse> {
  try {
    return await admin.api.getOrderList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function addFileToExistingMoverPackage(
  request: AddFileToMoverDocumentPackageAdminController_AgAddFileToMoverDocumentPackageRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.addFileToExistingMoverPackage(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function createNewMoverPackage(
  request: CreateNewMoverDocumentPackageAdminController_AgCreateNewMoverDocumentPackageRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.createNewMoverPackage(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverDocsList(
  request: GetMoverDocsListRequest,
): Promise<GetMoverDocumentPackageFileEntityListAdminController_AgMoverDocumentPackageFileEntityListAdminResponse> {
  try {
    return await admin.api.getMoverDocsList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverTransportDocsList(
  request: GetMoverTransportDocsListRequest,
): Promise<GetMoverTransportDocumentsListAdminController_AgMoverTransportDocumentsListAdminResponse> {
  try {
    return await admin.api.getMoverTransportDocsList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverDocsPackages(
  request: GetMoverDocsPackageListRequest,
): Promise<GetMoverDocumentPackageEntityListAdminController_AgMoverDocumentPackageEntityListAdminResponse> {
  try {
    return await admin.api.getMoverDocsPackagesList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCustomerDocsPackagesList(
  request: GetCustomerDocsPackageListRequest,
): Promise<GetCustomerDocumentPackageEntityListAdminController_AgCustomerDocumentPackageListAdminResponse> {
  try {
    return await admin.api.getCustomerDocsPackagesList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function updateMoverDoc(
  request: UpdateMoverDocumentPackageFileAdminController_AgUpdateMoverDocumentPackageFileStatusRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.updateMoverDoc(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function uploadMoverDoc(
  request: UploadMoverDocumentPackageFileAdminController_AgUploadMoverDocumentPackageFileAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.uploadMoverDoc(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function uploadBankDoc(
  request: AddBankStatementBillAdminController_BankStatementBillAGAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.uploadBankDoc(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function createMoverWithdrawRequest(
  request: WithdrawMoverBalanceAdminController_ApplicationWithdrawAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.createMoverWithdrawRequest(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function createUserWithdrawRequest(
  request: WithdrawUserBalanceAdminController_WithdrawBalanceInvoiceRequest,
): Promise<WithdrawUserBalanceAdminController_ResultBoolResponse> {
  try {
    return await admin.api.createUserWithdrawRequest(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function makeBankPayment(
  request: AddBankBillAdminController_BankPersonalBillAGRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.makeBankPayment(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getWithdrawList(
  request: GetAdminWithdrawListRequest,
): Promise<GetBillingApplicationWithdrawAdminController_BillingApplicationWithdrawAdminResponse> {
  try {
    return await admin.api.getWithdrawList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCreditList(
  request: GetAdminCreditListRequest,
): Promise<GetBillingCreditIssuedCustomerAdminController_CreditIssuedCustomerResponse> {
  try {
    return await admin.api.getCreditList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getInvoiceList(
  request: GetAdminInvoiceListRequest,
): Promise<GetBillingInvoiceAdminController_BillingInvoiceAdminResponse> {
  try {
    return await admin.api.getInvoiceList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOperationList(
  request: GetAdminOperationListRequest,
): Promise<GetBillingHistoryPersonalOperationAdminController_HistoryPersonalOperationAdminResponse> {
  try {
    return await admin.api.getOperationList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getPostpaymentList(
  request: GetAdminPostpaymentListRequest,
): Promise<GetBillingCreditOrderCustomerAdminController_CreditOrderCustomerAdminResponse> {
  try {
    return await admin.api.getPostpaymentList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBankPaymentList(
  request: GetAdminBankPaymentListRequest,
): Promise<GetBillingBankBillAdminController_BankBillOperationAdminResponse> {
  try {
    return await admin.api.getBankPaymentList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function setOriginalDocumentsSend(
  request: SetCustomerDocumentPackageOriginalDocumentsSentController_AgSetCustomerDocumentPackageOriginalDocumentsSentRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.setOriginalDocumentsSend(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCustomerStatusChain(request: { orderId: string }): Promise<AgOrderStatusEntity[]> {
  try {
    const response = await admin.api.getCustomerStatusChain(request);
    return response.status_chain ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverStatusChain(request: { orderId: string }): Promise<AgOrderStatusEntity[]> {
  try {
    const response = await admin.api.getMoverStatusChain(request);
    return response.status_chain ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function addDocToOrder(
  request: AddDocumentToOrderController_AgAddDocumentToOrderRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.addDocToOrder(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCompletedWorkAct(request: { user_uuid: string | null }): Promise<CompletedWorkActEntity[]> {
  try {
    const response = await admin.api.getCompletedWorkAct(request);
    return response.completed_work_act_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeTransportOwner(
  request: ChangeTransportOwnerController_ChangeTransportOwnerRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeTransportOwner(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function setTransportOriginalDocsReceived(
  request: SetMoverTransportDocumentOriginalReceivedController_AgMoverTransportDocumentOriginalReceivedRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.setTransportOriginalDocsReceived(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function setAccountancyOriginalDocsReceived(
  request: SetMoverDocumentPackageFileOriginalReceivedController_AgMoverDocumentPackageFileOriginalReceivedRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.setAccountancyOriginalDocsReceived(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getDriverList(
  request: GetDriverListController_DriverListRequest,
): Promise<GetDriverListController_DriverListResponse> {
  try {
    return await admin.api.getDriverList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTruckList(
  request: GetTruckListController_TruckAndSemiTrailerListRequest,
): Promise<GetTruckListController_TruckListResponse> {
  try {
    return await admin.api.getTruckList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getSemitrailerList(
  request: GetSemiTrailerListController_TruckAndSemiTrailerListRequest,
): Promise<GetSemiTrailerListController_SemiTrailerListResponse> {
  try {
    return await admin.api.getSemitrailerList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function cancelOrder(request: CancelOrderAdminController_AgOrderCancelRequest): Promise<ResultResponse> {
  try {
    const response = await admin.api.cancelOrder(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getAdminMenu(): Promise<AdminMenuItem[]> {
  try {
    return await admin.api.getAdminMenu();
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeFinishDate(
  request: ChangeContainerDeliveredAtController_AgChangeContainerDeliveredAtRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeFinishDate(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserDictionary(
  request: {
    account_type: "customer" | "mover";
  } | null,
): Promise<{
  users: UserShortInfoEntity[];
  logists: AdminLogistEntity[];
}> {
  try {
    const response = await admin.api.getUserDictionary(request);
    const logists = await admin.api.getLogistList();
    return {
      users: response.user_short_info_entities ?? [],
      logists: logists.logist_entities ?? [],
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getDriverWorkflowList(request: { driver_uniq_code: string }): Promise<WorkflowCheckEntity[]> {
  try {
    const response = await admin.api.getDriverWorkflowList(request);
    return response.workflow_check_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTruckWorkflowList(request: { truck_uniq_code: string }): Promise<WorkflowCheckEntity[]> {
  try {
    const response = await admin.api.getTruckWorkflowList(request);
    return response.workflow_check_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getSemitrailerWorkflowList(request: {
  semi_trailer_uniq_code: string;
}): Promise<WorkflowCheckEntity[]> {
  try {
    const response = await admin.api.getSemitrailerWorkflowList(request);
    return response.workflow_check_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserWorkflowList(request: { user_id: string }): Promise<WorkflowCheckEntity[]> {
  try {
    const response = await admin.api.getUserWorkflowList(request);
    return response.workflow_check_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getWorkflowList(request: {
  entity: "truck" | "semitrailer" | "driver" | "user";
  payload: {
    id: string;
  };
}): Promise<WorkflowCheckEntity[]> {
  switch (request.entity) {
    case "driver":
      return getDriverWorkflowList({
        driver_uniq_code: request.payload.id,
      });

    case "semitrailer":
      return getSemitrailerWorkflowList({
        semi_trailer_uniq_code: request.payload.id,
      });

    case "truck":
      return getTruckWorkflowList({
        truck_uniq_code: request.payload.id,
      });

    default:
      return getUserWorkflowList({
        user_id: request.payload.id,
      });
  }
}

export async function getAllWorkflowList(
  request: GetAdminAllManualCheckListRequest,
): Promise<GetAllManualChecksController_WorkflowAllManualChecksResponse> {
  try {
    return await admin.api.getAllWorkflowCheckList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBriefOrders(
  request: GetBriefOrderListRequest,
): Promise<AgGetOrderBriefInfoListAdminItemEntity[]> {
  try {
    const response = await admin.api.getBriefOrders(request);
    return response.entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBriefDowntimeOrders(
  request: GetBriefDowntimeOrderListRequest,
): Promise<AgUserDowntimeEntity[]> {
  try {
    const response = await admin.api.getBriefDowntimeOrders(request);
    return response.user_downtime_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBriefOrderList(
  request: GetBriefOrderListGeneralRequest,
): Promise<AgUserDowntimeEntity[] | AgGetOrderBriefInfoListAdminItemEntity[]> {
  if (request.type === "order") {
    if (request.is_registry) {
      return getBriefOrders({
        customer_id: request.user_id,
        filter: "roc_ready",
        hide_orders_in_registers: request.hide_orders_in_registers,
        container_delivered_date_to: request.container_delivered_date_to,
        sorting_field: request.sorting_field,
        sorting_order_by: request.sorting_order_by,
      });
    }
    return getBriefOrders(
      request.user_type === "customer"
        ? {
            customer_id: request.user_id,
            filter: "cdp_ready",
            hide_orders_in_registers: request.hide_orders_in_registers,
            container_delivered_date_to: request.container_delivered_date_to,
            sorting_field: request.sorting_field,
            sorting_order_by: request.sorting_order_by,
          }
        : {
            mover_id: request.user_id,
            filter: "mdp_ready",
            hide_orders_in_registers: request.hide_orders_in_registers,
            container_delivered_date_to: request.container_delivered_date_to,
            sorting_field: request.sorting_field,
            sorting_order_by: request.sorting_order_by,
          },
    );
  }

  return getBriefDowntimeOrders(
    request.user_type === "customer"
      ? {
          user_id: request.user_id,
          filter: "cdp_ready",
        }
      : {
          user_id: request.user_id,
          filter: "mdp_ready",
        },
  );
}

export async function getBriefDocPackages(request: GetBriefDocPackageListRequest): Promise<
  AgGetMoverDocumentPackageBriefInfoListAdminItemEntity &
    {
      label: string;
      value: string;
    }[]
> {
  try {
    const response = await admin.api.getBriefDocPackages(request);
    return response.entities
      ? response.entities.map((dp) => {
          return {
            ...dp,
            label: dp.package_id_code ?? "",
            value: dp.package_id ?? "",
          };
        })
      : [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckUserDetails(request: { check_uuid: string }): Promise<GetCheckDetailsResponse> {
  try {
    const response = await admin.api.getCheckUserDetails(request);
    return {
      profile_entity: response.profile_entity ?? undefined,
      document_file_entity: response.document_file_entity ?? undefined,
      check_info_entity: response.check_info_entity,
      comments: response.comments ?? undefined,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckDriverDetails(request: { check_uuid: string }): Promise<GetCheckDetailsResponse> {
  try {
    const response = await admin.api.getCheckDriverDetails(request);
    return {
      driver_entity: response.driver_entity ?? undefined,
      document_file_entity: response.document_file_entity ?? undefined,
      check_info_entity: response.check_info_entity,
      comments: response.comments ?? undefined,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckSemiTrailerDetails(request: { check_uuid: string }): Promise<GetCheckDetailsResponse> {
  try {
    const response = await admin.api.getCheckSemiTrailerDetails(request);
    return {
      semi_trailer_entity: response.semi_trailer_entity ?? undefined,
      document_file_entity: response.document_file_entity ?? undefined,
      check_info_entity: response.check_info_entity,
      comments: response.comments ?? undefined,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckTruckDetails(request: { check_uuid: string }): Promise<GetCheckDetailsResponse> {
  try {
    const response = await admin.api.getCheckTruckDetails(request);
    return {
      truck_entity: response.truck_entity ?? undefined,
      document_file_entity: response.document_file_entity ?? undefined,
      check_info_entity: response.check_info_entity,
      comments: response.comments ?? undefined,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCheckDetails(request: {
  check_uuid: string;
  entity: "user" | "driver" | "semitrailer" | "truck";
}): Promise<GetCheckDetailsResponse> {
  switch (request.entity) {
    case "driver":
      return getCheckDriverDetails({
        check_uuid: request.check_uuid,
      });

    case "semitrailer":
      return getCheckSemiTrailerDetails({
        check_uuid: request.check_uuid,
      });

    case "truck":
      return getCheckTruckDetails({
        check_uuid: request.check_uuid,
      });

    default:
      return getCheckUserDetails({
        check_uuid: request.check_uuid,
      });
  }
}

export async function closePostpayment(
  request: AdvancedCustomerCreditRepaymentAdminController_CustomerCreditRepaymentAdminAGRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.closePostpayment(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUserAuthTokens(request: {
  user_id: string;
}): Promise<UserSpoofingController_SecurityTokenResponse> {
  try {
    return await admin.api.getUserAuthTokens(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getDigitizationList(
  request: GetAdminDigitizationListRequest,
): Promise<GetDigitizationsListAdminController_AgGetDigitizationsListAdminResponse> {
  try {
    return await admin.api.getDigitizationList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function declineDocument(
  request: DeclineDocumentDigitizationAdminController_AgDeclineDigitizationAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.declineDocument(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCompanyList(
  request: Get1CCompanyListRequest,
): Promise<GetCompanyWith1CInfoListAdminController_AgCompanyWith1CInfoListAdminResponse> {
  try {
    return await admin.api.getCompanyList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeContractorInfo(
  request: UpdateContractor1CInfoAdminController_AgContractor1CInfoAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeContractorInfo(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getWidgets(request: GetWidgetFilterRequest): Promise<WidgetEntity> {
  try {
    const response = await admin.api.getWidgets(request);
    return {
      title: WidgetDictionary[request.aggregate_filter].title,
      id: request.aggregate_filter,
      description: WidgetDictionary[request.aggregate_filter].description,
      count: response.count_orders,
      modal: WidgetDictionary[request.aggregate_filter].modal,
      role: WidgetDictionary[request.aggregate_filter].role,
      account_type: WidgetDictionary[request.aggregate_filter].account_type,
    };
  } catch (error) {
    throw transformError(error);
  }
}

export async function recalculateTariff(
  request: AdminRecalculationTariffController_RecalculationTariffAdminRequest,
): Promise<RecalculateTariffOrderEntityResponse | undefined> {
  try {
    return await admin.api
      .recalculateTariff(request)
      .then((response) => toRecalculateTariffOrderEntityResponse(response.tariff_order_entity));
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeTariff(
  request: UpdateTariffAdminController_AgRecalculationOrderTariffAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeTariff(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

type CompanyResponseType = {
  prefix: string;
  name: string;
  inn: string;
  count: number;
};

export async function getWidgetCompany(request: GetWidgetCompanyFilterRequest): Promise<Map<any, any>> {
  try {
    const response = await admin.api.getWidgetCompany(request);
    return request.extended
      ? toCompanyResponseType2(response.list_company)
      : toCompanyResponseType(response.list_company);
  } catch (error) {
    throw transformError(error);
  }
}

function toCompanyResponseType(input?: ListCompanyWidgetOrdersOut[]): Map<any, any> {
  const map = new Map();
  if (!input || !input.length) return map;

  input.map((item) => {
    if (map.has(item.uniq_code)) {
      map.set(item.uniq_code, {
        ...map.get(item.uniq_code),
        count: map.get(item.uniq_code).count + 1,
        mover: map.get(item.uniq_code).mover + item.mover_tariff,
        customer: map.get(item.uniq_code).customer + item.customer_tariff,
      });
    } else {
      map.set(item.uniq_code, {
        prefix: item.uniq_code,
        name: item.name_company,
        inn: item.inn_company,
        customer: item.customer_tariff,
        mover: item.mover_tariff,
        logist: item.responsible_logist_user,
        count: 1,
      });
    }
  });

  return map;
}

function toCompanyResponseType2(input?: ListCompanyWidgetOrdersOut[]): Map<any, any> {
  const map = new Map();
  const innerMap = new Map();
  if (!input || !input.length) return map;

  input.map((item) => {
    if (innerMap.has(item.uniq_code)) {
      innerMap.set(item.uniq_code, {
        ...innerMap.get(item.uniq_code),
        count: innerMap.get(item.uniq_code).count + 1,
        mover: innerMap.get(item.uniq_code).mover + item.mover_tariff,
        customer: innerMap.get(item.uniq_code).customer + item.customer_tariff,
      });
    } else {
      innerMap.set(item.uniq_code, {
        prefix: item.uniq_code,
        name: item.name_company,
        inn: item.inn_company,
        customer: item.customer_tariff,
        mover: item.mover_tariff,
        logist: item.responsible_logist_user,
        count: 1,
      });
    }
  });

  input.map((item) => {
    if (map.has(item.responsible_logist_user)) {
      const innerMap = map.get(item.responsible_logist_user);
      if (innerMap.has(item.uniq_code)) {
        innerMap.set(item.uniq_code, {
          ...innerMap.get(item.uniq_code),
          count: innerMap.get(item.uniq_code).count + 1,
          mover: innerMap.get(item.uniq_code).mover + item.mover_tariff,
          customer: innerMap.get(item.uniq_code).customer + item.customer_tariff,
        });
      } else {
        innerMap.set(item.uniq_code, {
          prefix: item.uniq_code,
          name: item.name_company,
          inn: item.inn_company,
          customer: item.customer_tariff,
          mover: item.mover_tariff,
          logist: item.responsible_logist_user,
          count: 1,
        });
      }
    } else {
      map.set(
        item.responsible_logist_user,
        new Map([
          [
            item.uniq_code,
            {
              prefix: item.uniq_code,
              name: item.name_company,
              inn: item.inn_company,
              customer: item.customer_tariff,
              mover: item.mover_tariff,
              logist: item.responsible_logist_user,
              count: 1,
            },
          ],
        ]),
      );
    }
  });

  return new Map([
    ["inner", innerMap],
    ["outer", map],
  ]);
}

export async function calculateDowntime(
  request: AdminTariffsDowntimeController_AdminTariffDowntimeRequest,
): Promise<AdminTariffsDowntimeController_AdminTariffDowntimeResponse> {
  try {
    return await admin.api.calculateDowntime(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function addDowntime(request: AddDowntimeToOrderController_AgAddDowntimeRequest): Promise<ResultResponse> {
  try {
    const response = await admin.api.addDowntime(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function checkOrderDocPac(request: {
  profile: "mover" | "customer";
  date: string;
  user_id: string;
}): Promise<
  | OrdersForCheckCustomerDocumentPackageController_OrdersForCheckDocumentPackageResponse
  | OrdersForCheckMoverDocumentPackageController_OrdersForCheckDocumentPackageResponse
> {
  try {
    if (request.profile === "customer")
      return await admin.api.checkOrderDocPacCustomer({
        orders_for_check_document_package: {
          user_id: request.user_id,
          date_order_to: request.date,
        },
      });

    return await admin.api.checkOrderDocPacMover({
      orders_for_check_document_package: {
        user_id: request.user_id,
        date_order_to: request.date,
      },
    });
  } catch (error) {
    throw transformError(error);
  }
}

export async function getLogistList(): Promise<AdminLogistEntity[]> {
  try {
    const response = await admin.api.getLogistList();
    return response.logist_entities;
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeResponsibleLogist(
  request: ChangeResponsibleLogistForCustomerController_ChangeResponsibleLogistForCustomerRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeResponsibleLogist(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeResponsibleLogistOrder(
  request: ChangeResponsibleLogistForOrderController_AgChangeResponsibleLogistForOrderRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.changeResponsibleLogistOrder(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function digitizeDoc(
  request: DigitizeDocumentAdminController_AgDigitizeDocumentManuallyAdminRequest,
): Promise<ResultResponse> {
  try {
    const response = await admin.api.digitizeDoc(request);
    return admin.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOrderListSearch(request: string): Promise<AgOrderShortInfoListAdminEntity[]> {
  try {
    const response = await admin.api.getOrderListSearch(request);
    return response.entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function getDowntimeList(
  request: GetDowntimeListAdminController_AgGetDowntimeListAdminRequest,
): Promise<GetDowntimeListAdminController_AgDowntimeListAdminResponse> {
  try {
    return await admin.api.getDowntimeList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getDigitizationOrderList(request: {
  query: string;
  mover_id?: string;
  doc_type: "transport" | "accounting";
}): Promise<AgOrderShortInfoListAdminEntity[] | AgGetOrderBriefInfoListAdminItemEntity[]> {
  if (request.doc_type === "accounting") {
    return getBriefOrders({
      mover_id: request.mover_id,
      filter: "mover_flow_active",
    });
  }

  return getOrderListSearch(request.query);
}

export async function getLogistKpiList(
  request: GetLogistKpiListRequest,
): Promise<GetLogistKpiController_LogistKpiResponse> {
  try {
    return await admin.api.getLogistKpiList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function uploadDigitizationFile(
  request: UploadFileAndCreateDigitizationAdminController_AgUploadFileAndCreateDigitizationAdminRequest,
): Promise<UploadFileAndCreateDigitizationAdminController_ResultBoolResponse> {
  try {
    return await admin.api.uploadDigitizationFile(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function annulatePackage(
  request: CancelCustomerDocumentPackageController_AgCancelCustomerDocumentPackageRequest,
): Promise<CancelCustomerDocumentPackageController_ResultBoolResponse> {
  try {
    return await admin.api.annulatePackage(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeContainerNumber(
  request: ChangeOrderContainerNumberController_AgChangeOrderContainerNumberRequest,
): Promise<ChangeOrderContainerNumberController_ResultBoolResponse> {
  try {
    return await admin.api.changeContainerNumber(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changePickupTerminal(
  request: ChangeOrderPickupTerminalController_AgChangeOrderPickupTerminalRequest,
): Promise<ChangeOrderPickupTerminalController_ResultBoolResponse> {
  try {
    return await admin.api.changePickupTerminal(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeDeliveryTerminal(
  request: ChangeOrderDeliveryTerminalController_AgChangeOrderDeliveryTerminalRequest,
): Promise<ChangeOrderDeliveryTerminalController_ResultBoolResponse> {
  try {
    return await admin.api.changeDeliveryTerminal(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeRejectMover(
  request: ChangeOrderRejectMoverController_AgChangeOrderRejectMoverRequest,
): Promise<ChangeOrderRejectMoverController_ResultBoolResponse> {
  try {
    return await admin.api.changeRejectMover(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function cancelMoverPackage(
  request: CancelMoverDocumentPackageController_AgCancelMoverDocumentPackageRequest,
): Promise<CancelMoverDocumentPackageController_ResultBoolResponse> {
  try {
    return await admin.api.cancelMoverPackage(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function addFileToMoverPackage(
  request: AddFileToMoverDocumentPackageV2AdminController_AgAddFileToMoverDocumentPackageV2Request,
): Promise<AddFileToMoverDocumentPackageV2AdminController_ResultBoolResponse> {
  try {
    return await admin.api.addFileToMoverPackage(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function createNewMoverPackage2(
  request: CreateMoverDocumentPackageAdminController_AgCreateNewMoverDocumentPackageV2Request,
): Promise<CreateMoverDocumentPackageAdminController_ResultBoolResponse> {
  try {
    return await admin.api.createNewMoverPackage2(request);
  } catch (error) {
    throw transformError(error);
  }
}

export type FinancialAggregateItem = {
  uuid: string;
  count: number;
  managers?: FinancialAggregateItem[];
};
export type FinancialStatisticsEntity = {
  items: FinancialAggregateItem[];
  byResponsibleLogist?: FinancialAggregateItem[];
  aggregateNumber: number;
  aggregateMeasure: "rub" | "item";
  aggregateName: string;
  entityType: "user" | "logist";
  nullValue?: boolean;
};

export type FinancialDynamicAccountTablesStatisticsEntity = {
  filter: {
    financial_account_tables_type:
      | "general"
      | "atWork"
      | "needSetImplementation"
      | "implementation"
      | "overdueAccountsReceivable30"
      | "overdueAccountsReceivable90";
    financial_account_tables_user_type: "customer" | "logist";
  };
};

export type FinancialDynamicStatisticsEntity = {
  created_date_from: string;
  created_date_to: string;
  count_orders: boolean;
};

export async function getFinancialStatistics(request: {
  created_date_from: string;
  created_date_to: string;
}): Promise<FinancialStatisticsEntity[]> {
  try {
    const response = await admin.api.getFinancialStatistics(request);
    return mapFinancialStatistics(response);
  } catch (error) {
    throw transformError(error);
  }
}

const mapFinancialStatistics = (
  entity: FinancialStatisticsController_FinancialStatisticsAdminResponse,
): FinancialStatisticsEntity[] => {
  const expensiveNdsEntity: FinancialStatisticsEntity = {
    items:
      entity.out_entity?.expensive_entity?.movers?.map((mover) => ({
        uuid: mover.company_inn ?? "",
        count: mover.company_sum ?? 0,
        managers: mover.company_managers?.map((manager) => ({
          uuid: manager.user_uuid ?? "",
          count: manager.sum ?? 0,
        })),
      })) ?? [],
    aggregateNumber: entity.out_entity?.expensive_entity?.sum ?? 0,
    aggregateName: "Затратная (с НДС)",
    aggregateMeasure: "rub",
    entityType: "user",
  };

  const expensiveNoNdsEntity: FinancialStatisticsEntity = {
    items:
      entity.out_entity?.expensive_entity?.movers_no_nds?.map((mover) => ({
        uuid: mover.company_inn ?? "",
        count: mover.company_sum ?? 0,
        managers: mover.company_managers?.map((manager) => ({
          uuid: manager.user_uuid ?? "",
          count: manager.sum ?? 0,
        })),
      })) ?? [],
    aggregateNumber: entity.out_entity?.expensive_entity?.sum_no_nds ?? 0,
    aggregateName: "Затратная (без НДС)",
    aggregateMeasure: "rub",
    entityType: "user",
  };

  const marginEntity: FinancialStatisticsEntity = {
    items:
      entity.out_entity?.margin_entity?.logist?.map((logist) => ({
        uuid: logist.user_uuid ?? "",
        count: logist.sum ?? 0,
      })) ?? [],
    aggregateNumber: entity.out_entity?.margin_entity?.sum ?? 0,
    aggregateName: "Маржа без НДС",
    aggregateMeasure: "rub",
    entityType: "logist",
  };

  const turnoverNdsEntity: FinancialStatisticsEntity = {
    items:
      entity.out_entity?.turnover_entity?.customers?.map((customer) => ({
        uuid: customer.company_inn ?? "",
        count: customer.company_sum ?? 0,
        managers: customer.company_managers?.map((manager) => ({
          uuid: manager.user_uuid ?? "",
          count: manager.sum ?? 0,
        })),
      })) ?? [],
    aggregateNumber: entity.out_entity?.turnover_entity?.sum ?? 0,
    aggregateName: "Оборотка (с НДС)",
    aggregateMeasure: "rub",
    entityType: "user",
  };

  const turnoverNoNdsEntity: FinancialStatisticsEntity = {
    items:
      entity.out_entity?.turnover_entity?.customers_no_nds?.map((customer) => ({
        uuid: customer.company_inn ?? "",
        count: customer.company_sum ?? 0,
        managers: customer.company_managers?.map((manager) => ({
          uuid: manager.user_uuid ?? "",
          count: manager.sum ?? 0,
        })),
      })) ?? [],
    aggregateNumber: entity.out_entity?.turnover_entity?.sum_no_nds ?? 0,
    aggregateName: "Оборотка (без НДС)",
    aggregateMeasure: "rub",
    entityType: "user",
  };

  return [turnoverNdsEntity, expensiveNdsEntity, marginEntity, turnoverNoNdsEntity, expensiveNoNdsEntity];
};

const mapCountOrderStatistics = (
  entity: CountOrdersStatisticsAdminController_CountOrdersStatisticsAdminResponse,
): FinancialStatisticsEntity[] => {
  const customerEntity: FinancialStatisticsEntity = {
    items:
      entity.out_entity?.count_orders_entity?.customers?.map((customer) => ({
        uuid: customer.inn ?? "",
        count: customer.count ?? 0,
        managers: customer.user_entities?.map((manager) => ({
          uuid: manager.user_uuid ?? "",
          count: manager.count ?? 0,
        })),
      })) ?? [],
    aggregateNumber: entity.out_entity?.count_orders_entity?.count ?? 0,
    aggregateName: "Кол-во в разрезе Заказчиков",
    aggregateMeasure: "item",
    entityType: "user",
  };

  const moverEntity: FinancialStatisticsEntity = {
    items:
      entity.out_entity?.count_orders_entity?.movers?.map((mover) => ({
        uuid: mover.inn ?? "",
        count: mover.count ?? 0,
        managers: mover.user_entities?.map((manager) => ({
          uuid: manager.user_uuid ?? "",
          count: manager.count ?? 0,
        })),
      })) ?? [],
    aggregateNumber: entity.out_entity?.count_orders_entity?.count ?? 0,
    aggregateName: "Кол-во в разрезе Перевозчиков",
    aggregateMeasure: "item",
    entityType: "user",
  };

  const logistEntity: FinancialStatisticsEntity = {
    items:
      entity.out_entity?.count_orders_entity?.logist?.map((logist) => ({
        uuid: logist.user_uuid ?? "",
        count: logist.count ?? 0,
      })) ?? [],
    aggregateNumber: entity.out_entity?.count_orders_entity?.count ?? 0,
    aggregateName: "Кол-во в разрезе Логистов",
    aggregateMeasure: "item",
    entityType: "logist",
  };

  return [customerEntity, moverEntity, logistEntity];
};
export async function getFinancialStatisticsAccountTables(request: {
  financial_account_tables_type:
    | "general"
    | "atWork"
    | "needSetImplementation"
    | "implementation"
    | "overdueAccountsReceivable30"
    | "overdueAccountsReceivable90";
  financial_account_tables_user_type: "customer" | "logist";
}): Promise<FinancialStatisticsEntity[]> {
  try {
    const response = await admin.api.getFinancialStatisticsAccountTables(request);
    return mapFinancialStatisticsAccountTables(response.out_entity?.account_tables_type_entities ?? []);
  } catch (error) {
    throw transformError(error);
  }
}

const accountTablesTypes = {
  needSetImplementation: "Нужно выставить реализацию",
  implementation: "Реализация",
  overdueAccountsReceivable30: "Просроченная дебиторка 30 дней",
  overdueAccountsReceivable90: "Просроченная дебиторка 90 дней",
  atWork: "В работе",
  general: "Общая",
};

const mapFinancialStatisticsAccountTables = (entity: AccountTablesTypeEntity[]): FinancialStatisticsEntity[] => {
  return entity.map((item) => ({
    items:
      item.customer_companies?.map((customer) => ({
        uuid: customer.company_inn ?? "",
        count: customer.company_sum ?? 0,
        managers: customer.company_managers?.map((manager) => ({
          uuid: manager.user_uuid ?? "",
          count: manager.sum ?? 0,
        })),
      })) ?? [],
    aggregateNumber: item.sum_account_tables_type ?? 0,
    aggregateName:
      accountTablesTypes[item.financial_account_tables_enum?.value as keyof typeof accountTablesTypes] ?? "",
    aggregateMeasure: "rub",
    entityType: "logist",
    byResponsibleLogist:
      item.logistics?.map((logist) => ({
        uuid: logist.user_uuid ?? "",
        count: logist.sum ?? 0,
        managers: logist.customers?.map((customer) => ({
          uuid: customer.company_inn ?? "",
          count: customer.company_sum ?? 0,
          managers: customer.company_managers?.map((manager) => ({
            uuid: manager.user_uuid ?? "",
            count: manager.sum ?? 0,
          })),
        })),
      })) ?? [],
  }));
};

export async function getReestrListForCustomer(request: {
  customer_id?: string;
  status?: "created" | "generated_document_package" | "cancelled";
  offset: number;
  limit: number;
}): Promise<GetRegistryOrdersForCustomerEntityListController_AgRegistryOrdersCustomerListResponse> {
  try {
    return await admin.api.getReestrListForCustomer(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function generateReestrForCustomer(
  request: CreateRegistryOrdersForCustomerController_AgCreateRegistryOrdersForCustomerRequest,
): Promise<CreateRegistryOrdersForCustomerController_ResultBoolResponse> {
  try {
    return await admin.api.generateReestrForCustomer(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function cancelReestrForCustomer(
  request: CancelRegistryOrdersCustomerController_AgCancelRegistryOrdersForCustomerRequest,
): Promise<CancelRegistryOrdersCustomerController_ResultBoolResponse> {
  try {
    return await admin.api.cancelReestrForCustomer(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeReestrForCustomer(
  request: ChangeRegistryOrdersForCustomerController_AgChangeRegistryOrdersForCustomerRequest,
): Promise<ChangeRegistryOrdersForCustomerController_ResultBoolResponse> {
  try {
    return await admin.api.changeReestrForCustomer(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getReestrListByUniqForCustomer(request: {
  registry_order_uniq_code: string;
}): Promise<GetRegistryOrdersForCustomerEntityListController_AgRegistryOrdersCustomerListResponse> {
  try {
    return await admin.api.getReestrListByUniqForCustomer(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function generateFromReestrForCustomer(
  request: GenerateCustomerDocumentPackageFromRegistryController_AgGenerateCustomerDocumentPackageFromRegistryRequest,
): Promise<GenerateCustomerDocumentPackageFromRegistryController_ResultBoolResponse> {
  try {
    return await admin.api.generateFromReestrForCustomer(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getReestrDetailsForCustomer(request: {
  registry_id_code: string;
}): Promise<RegistryOrdersCustomerEntity> {
  try {
    return await admin.api
      .getReestrDetailsForCustomer(request)
      .then((response) => response.registry_orders_customer_entity);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getReestrListForMover(request: {
  mover_id?: string;
  manager_id?: string;
  offset: number;
  limit: number;
}): Promise<GetRegistryOrdersListForMoverController_AgRegistryOrdersMoverListResponse> {
  try {
    return await admin.api.getReestrListForMover(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function generateReestrForMover(
  request: CreateRegistryOrdersForMoverController_AgCreateRegistryOrdersForMoverRequest,
): Promise<CreateRegistryOrdersForMoverController_ResultBoolResponse> {
  try {
    return await admin.api.generateReestrForMover(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getCountOrderStatistics(request: {
  created_date_from: string;
  created_date_to: string;
}): Promise<FinancialStatisticsEntity[]> {
  try {
    const response = await admin.api.getCountOrderStatistics(request);
    return mapCountOrderStatistics(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getAssistantKpiList(
  request: GetAssistantKpiListFilter,
): Promise<GetLogistAssistantKpiController_LogistAssistantKpiResponse> {
  try {
    return await admin.api.getAssistantKpiList(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function restoreAtiCargo(
  request: RestoreFromArchiveAtiCargoController_AgRestoreFromArchiveAtiCargoRequest,
): Promise<RestoreFromArchiveAtiCargoController_ResultBoolResponse> {
  try {
    return await admin.api.restoreAtiCargo(request);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeDowntimeActive(
  request: ChangeActiveDowntimeForUserController_AgChangeActiveDowntimeForUserRequest,
): Promise<ChangeActiveDowntimeForUserController_ResultBoolResponse> {
  try {
    return await admin.api.changeDowntimeActive(request);
  } catch (error) {
    throw transformError(error);
  }
}
